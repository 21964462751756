@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

:root {
    /* EFE9DF */
    --main-color: #efe9df;
    --font-color: #cec9c0;
    --date-color: #a9a49a;
    --important-text: #8AAAE5;
}

.App {
    text-align: center;
    background: linear-gradient(to bottom, #202020, #111119);
}

#portfolio {
    background: linear-gradient(to bottom, #202020, #111119);
    min-height: 100vh; /* Ensure it takes at least the full height */
    display: flex;
    flex-direction: row;
}

#portleft {
    width: 40%;
    height: 100vh;
    top: 0;
    left: 0;
    position: sticky;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space between the text and the icons */
    padding: 28px; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding is included in total width/height */
}

#portright {
    width: 60%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.mainfont {
    font-family: 'Montserrat', sans-serif;
    color: var(--main-color);
}

.font {
    font-family: 'Montserrat', sans-serif;
    color: var(--font-color);
}

.under_score {
    font-family: 'Montserrat', sans-serif;
    color: var(--important-text);
}

.font2 {
    font-family: 'Montserrat', sans-serif;
    color: var(--date-color);
}

.description {
    height: auto;
    /*border: 2px solid black;*/
}

.experience_box {
    display: flex;
    flex-direction: row;
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 1.33s ease, transform 1.33s ease;
}

.initBoxesLeft {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 1.33s;
}

.initBoxesRight {
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 1.33s;
}

.experience_box[data-animated="true"],
.initBoxesLeft[data-animated="true"],
.initBoxesRight[data-animated="true"],
.projects[data-animated="true"],
.publications[data-animated="true"] {
    opacity: 1;
    transform: translateX(0);
}

.checkpoint {
    transition: font-weight 0.3s ease;
}

.checkpoint.font-bold {
    font-weight: bold;
}

.projects {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
    backdrop-filter: blur(10px); /* Adds a glassy blur effect */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for a 3D look */
    padding: 5%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 1.33s;

}

.projects:hover {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6); /* Increase shadow for a lifted effect */
}

.publications {
    opacity: 0;
    transform: translateX(100px);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    transition: opacity 1.33s;

}

.placeholder {
    justify-content: center;
}

.placeholder2 {
    padding-right: 5rem
}

/* Add these media queries at the end of your existing CSS */
@media screen and (max-width: 1024px) {
    #portfolio {
        flex-direction: column;
    }

    .placeholder2 {
        padding-right: 0;
    }

    #portleft {
        width: 100%;
        height: auto;
        position: static;
        padding: 20px;
    }

    #portright {
        width: 100%;
        padding: 20px;
    }

    #portright > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .experience_box {
        flex-direction: column;
        align-items: center;
    }

    .experience_box > div {
        width: 100% !important;
    }

    .projects, .publications {
        width: 100%;
        max-width: 600px; /* Adjust this value as needed */
    }

    .initBoxesLeft, .initBoxesRight {
        transform: translateX(0);
    }

    #checkpoints {
        align-items: center;
    }

    /*.text-left {*/
    /*    text-align: center;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    #portfolio {
        flex-direction: column;
    }

    #portleft {
        width: 100%;
        height: auto;
        position: static;
        padding: 20px;
    }

    #portright {
        width: 100%;
        padding: 20px;
    }

    .placeholder {
        padding: 1em;
    }

    .experience_box {
        flex-direction: column;
    }

    .experience_box > div {
        width: 100% !important;
    }

    .projects {
        width: 100%;
        padding: 15px;
    }

    .publications {
        width: 100%;
    }

    .initBoxesLeft, .initBoxesRight {
        transform: translateX(0);
    }
}

@media screen and (max-width: 480px) {
    .mainfont.text-4xl {
        font-size: 2rem;
    }

    .mainfont.text-2xl {
        font-size: 1.5rem;
    }

    #checkpoints {
        padding: 10px;
    }

    .p-20 {
        padding: 10px;
    }

    .pr-40 {
        padding-right: 10px;
    }
}
